import { Controller } from "@hotwired/stimulus"
import Dropzone from "dropzone"
import { Turbo } from "@hotwired/turbo-rails"

export default class extends Controller {
  dropzone: any

  connect() {
    this.dropzone = new Dropzone(this.element.querySelector("form.dropzone"), {
      headers: {
        // @ts-ignore
        "X-CSRF-Token": document.head.querySelector("meta[name=csrf-token]")?.content,
        Accept: "text/vnd.turbo-stream.html",
      },
    })
    this.dropzone.on("success", (file) => {
      this.dropzone.removeFile(file)
      Turbo.renderStreamMessage(file.xhr.response)
    })
  }
}
