// Set up Stimulus
import { Application } from "@hotwired/stimulus"
import { registerControllers } from "stimulus-vite-helpers"

export default class SetupStimulus {
  static start() {
    // const app = Application.start()
    // application.debug = false
    // ;(window as any).Stimulus = application
    //
    // @ts-ignore
    const controllers = import.meta.glob("./controllers/**/*_controller.*s", { eager: true })
    registerControllers(Application.start(), controllers)
  }
}
