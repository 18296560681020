import { Controller } from "@hotwired/stimulus"
import { parseDecimal } from "../parse_decimal"
import I18n from "../i18n"

export default class extends Controller {
  static targets = ["amountInput"]
  declare amountInputTarget: HTMLInputElement

  connect() {
    this.parseExpression()
  }

  parseExpression() {
    this.removeOldFeedback()
    try {
      if (this.amountInputTarget.value === "") {
        return
      }

      const result = parseDecimal(this.amountInputTarget.value)
      const oldValue = this.amountInputTarget.value
      this.amountInputTarget.value = new Intl.NumberFormat("nb-NO", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })
        .format(result.toNumber())
        // Use regular minus instead of the special minus that Intl.NumberFormat seems like use
        .replaceAll("\u2212", "-")
      // Simulate input if we have changed anything. This makes sure we update places where we listen to input changes
      // to show calculations immediately
      if (oldValue !== this.amountInputTarget.value) {
        this.amountInputTarget.dispatchEvent(new InputEvent("input"))
      }
    } catch (e) {
      if (e instanceof SyntaxError) {
        if (e.message == "multiple decimal separators" && !this.hasInvalidFeedback()) {
          this.amountInputTarget.parentNode.appendChild(this.attachCommaPrompt())
        } else {
          console.log(e)
        }
      } else {
        throw e
      }
    }
  }

  clear(): void {
    if (this.amountInputTarget.value === "0,00") {
      this.amountInputTarget.value = ""
    }
  }

  attachCommaPrompt(): HTMLElement {
    const feedback = document.createElement("div")
    feedback.classList.add("comma-prompt")
    feedback.innerHTML = `<div class="small alert alert-warning">${I18n.t(
      "simple_form.hints.amount.only_single_comma_needed"
    )} 
      </div>`
    return feedback
  }

  removeOldFeedback(): void {
    let oldFeedback = this.element.querySelector(".comma-prompt")
    if (oldFeedback) {
      oldFeedback.parentNode.removeChild(oldFeedback)
    }
  }

  hasInvalidFeedback(): boolean {
    return this.amountInputTarget.classList.contains("is-invalid")
  }
}
