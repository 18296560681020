import Decimal from "decimal.js"

/*
 * Helper method which allows us to accept:
 *   - formulas (such as 1 + 1)
 *   - norwegian numbers (such as 1234,56)
 * Accepts both . and , as decimal separator and therefore does NOT accept , as thousands separator
 * It returns a "Decimal"
 * It returns Decimal NaN if we are not able to parse the syntax. This can be changed by setting error_value
 * * Parts of this code is adopted from https://jorendorff.github.io/calc/docs/calculator-parser.html
 */

export function parseDecimal(code: string, error_value: Decimal = new Decimal(NaN)): Decimal {
  if (code == null || code.trim() === "") {
    return error_value
  }

  try {
    let parser = new Parser(code.replaceAll(/\s/g, ""))
    let parseTree = parser.parseExpr()
    if (!parser.consumedAll()) {
      const peek = parser.peek()
      if (peek === "," || peek === ".") {
        // The user has given us numbers with multiple decimal separators, we don`t allow that:
        throw new SyntaxError("multiple decimal separators")
      } else {
        console.log("unexpected '" + parser.peek() + "' Input value:'" + code + "'")
      }
    }
    return evaluate(parseTree)
  } catch (e) {
    if (e instanceof SyntaxError) {
      return error_value
    } else {
      throw e
    }
  }
}

function evaluate(obj): Decimal {
  switch (obj.type) {
    case "number":
      return parseNumber(obj.value)
    case "+":
      return evaluate(obj.left).plus(evaluate(obj.right))
    case "-":
      return evaluate(obj.left).minus(evaluate(obj.right))
    case "*":
      return evaluate(obj.left).times(evaluate(obj.right))
    case "/":
      return evaluate(obj.left).div(evaluate(obj.right))
    case "infix_minus":
      return evaluate(obj.right).times(-1)
  }
  throw new SyntaxError("Unknown object type:" + obj.type)
}

function parseNumber(val): Decimal {
  return new Decimal(val.replace(",", "."))
}

function isNumber(token) {
  return token !== undefined && token.match(/^\d+[\.,]?\d*$/) !== null
}

function isName(token) {
  return token !== undefined && token.match(/^[A-Za-z]+$/) !== null
}

function tokenize(code: string): string[] {
  let results = []
  let tokenRegExp = /\s*([A-Za-z]+|\d+[\.,]?\d*|\S)\s*/g

  let m
  while ((m = tokenRegExp.exec(code)) !== null) results.push(m[1])
  return results
}

class Parser {
  position: number
  tokens: string[]

  constructor(code) {
    this.position = 0
    this.tokens = tokenize(code)
  }

  peek() {
    return this.tokens[this.position]
  }

  consume(token) {
    if (token !== this.tokens[this.position]) {
      throw new Error(`Expected ${token} to eq ${this.tokens[this.position]}`)
    }
    this.position++
  }

  parsePrimaryExpr() {
    let t = this.peek()

    if (isNumber(t)) {
      this.consume(t)
      return { type: "number", value: t }
    } else if (isName(t)) {
      this.consume(t)
      return { type: "name", id: t }
    } else if (t === "-") {
      // Poor mans prefix support:
      this.consume(t)
      return { type: "infix_minus", id: t, right: this.parseExpr() }
    } else if (t === "(") {
      this.consume(t)
      let expr = this.parseExpr()
      if (this.peek() !== ")") throw new SyntaxError("expected )")
      this.consume(")")
      return expr
    } else {
      throw new SyntaxError("expected a number, or parentheses")
    }
  }

  parseMulExpr() {
    let expr = this.parsePrimaryExpr()
    let t = this.peek()
    while (t === "*" || t === "/") {
      this.consume(t)
      let rhs = this.parsePrimaryExpr()
      expr = { type: t, left: expr, right: rhs }
      t = this.peek()
    }
    return expr
  }

  parseExpr() {
    let expr = this.parseMulExpr()
    let t = this.peek()
    while (t === "+" || t === "-") {
      this.consume(t)
      let rhs = this.parseMulExpr()
      expr = { type: t, left: expr, right: rhs }
      t = this.peek()
    }
    return expr
  }

  consumedAll(): boolean {
    return this.position === this.tokens.length
  }
}
