import StimulusFlatpickr from "stimulus-flatpickr"

import { Norwegian } from "flatpickr/dist/l10n/no.js"
import { english } from "flatpickr/dist/l10n/default.js"
import flatpickr from "flatpickr"
import I18n from "../i18n"

// create a new Stimulus controller by extending stimulus-flatpickr wrapper controller
export default class extends StimulusFlatpickr {
  locales = {
    nb: Norwegian,
    en: english,
  }
  config: Object
  fp: flatpickr.Instance

  hasBeenDisconnected = false

  initialize() {
    super.initialize()
  }

  disconnect() {
    this.hasBeenDisconnected = true
  }

  connect() {
    // We avoid rerunning this controller if it has been disconnected.
    // One example where this happens is when we move an element with this controller in the DOM.
    // We then get a connect&disconnect&connect sequence, and this makes us lose our input value.
    // One place we use this is with the controller "move-element" which moves a modal to a different element
    // If you need to change this logic, you can make sure this still works by checking that
    // the dates in the edit employee modal are pre-populated
    if (this.hasBeenDisconnected) return

    this.config = {
      ...this.config, //spread options in case some where defined in initialize
      locale: this.locales[I18n.locale],
      // NB! Other default are set in date_picker_input.rb
      // The reason is that it makes it easier to set the default alt-format with I18n from the project
    }
    super.connect()

    // 2022-06-29 We don`t use the calendar icon so this part if commented out
    // After upgrading to Bootstrap 5 this probably doesnt work since input-group-append was removed
    // If we decide to add back the calendar icon we will probably need to fix this code

    // const picker = this.fp
    // Make the calendar icon in the input field clickable
    // const pickerButton = picker._input.parentNode.querySelector('.input-group-append')
    // if (pickerButton) {
    //   pickerButton.addEventListener('click', function () {
    //     picker.open()
    //   })
    // }
  }
}
