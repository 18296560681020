import { I18n } from "i18n-js"

// TODO: Need to figure out how to get the locales, for now we just dont do any I18n
// import translations from "locales.json";
// const i18n = new I18n(translations);
const i18n = new I18n({})

// @ts-ignore
window.i18n = i18n

export default i18n
