import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["printedDate", "dueDate", "useTodaysDateForFinalizing"]

  declare printedDateTarget: HTMLInputElement
  declare dueDateTarget: HTMLInputElement
  declare hasUseTodaysDateForFinalizingTarget: Boolean
  declare useTodaysDateForFinalizingTarget: HTMLInputElement

  connect() {
    if (this.hasUseTodaysDateForFinalizingTarget) {
      this.setInvoiceFields()
    }
  }

  setInvoiceFields() {
    // @ts-ignore
    for (const child of this.printedDateTarget.parentElement.children) {
      child.disabled = this.useTodaysDateForFinalizingTarget.checked
    }
    // @ts-ignore
    for (const child of this.dueDateTarget.parentElement.children) {
      child.disabled = this.useTodaysDateForFinalizingTarget.checked
    }
  }
}
