import SetupStimulus from "../setupStimulus"

SetupStimulus.start()

// Example: Load Rails libraries in Vite.
//
// import * as Turbo from '@hotwired/turbo'
import { Turbo } from "@hotwired/turbo-rails"
// Turbo.session.drive = false
Turbo.start()

import "trix"

import "bootstrap"

//
// import ActiveStorage from '@rails/activestorage'
// ActiveStorage.start()
//
// // Import all channels.
// const channels = import.meta.globEager('./**/*_channel.js')

// Example: Import a stylesheet in app/frontend/index.css
// import '~/index.css'
