import NestedForm from "./nested_form_controller"

function selectorString(deliveryId: string, attributeName: string): string {
  return `[name="contract[deliveries_attributes][${deliveryId}][${attributeName}]"]`
}
function getInput(event, attributeName: string) {
  const trNode = event.target.closest("tr")
  const index = trNode.dataset.index
  return trNode.querySelector(selectorString(index, attributeName))
}

export default class extends NestedForm {
  connect() {
    super.connect()
  }

  remove(event) {
    event.preventDefault()
    const trNode = event.target.closest("tr")
    const newRecord = getInput(event, "id").value == ""
    console.log("newRecord", newRecord)
    if (newRecord) {
      trNode.remove()
    } else {
      const destroyInput = getInput(event, "_destroy")
      destroyInput.value = "true"
      trNode.classList.add("d-none")
    }
  }

  copy(event) {
    event.preventDefault()

    const time = new Date().getTime().toString()
    const content = this.templateTarget.innerHTML.replace(/NEW_RECORD/g, time)
    this.targetTarget.insertAdjacentHTML("beforebegin", content)

    const trNode = event.target.closest("tr")
    const sourceIndex = trNode.dataset.index

    const attributes = [
      "specification1",
      "specification2",
      "specification3",
      "width",
      "thickness",
      "length",
      "quantity",
      "price",
      "delivered_in",
      "payment_pr",
    ]

    for (let i = 0; i < attributes.length; i++) {
      const attribute = attributes[i]
      const target = this.element.querySelector(selectorString(time, attribute)) as HTMLInputElement
      const source = trNode.querySelector(selectorString(sourceIndex, attribute))
      if (!target || !source) {
        // console.log(`Could not find source or target for ${attribute}`)
      } else {
        target.value = source.value
      }
    }
  }

  cancel(event) {
    event.preventDefault()

    const trNode = event.target.closest("tr")

    trNode.classList.add("strikeout")
    trNode.querySelectorAll("input, select").forEach((input) => {
      input.disabled = true
    })
    // Reenable cancelled
    let cancelledInput = getInput(event, "cancelled")
    cancelledInput.value = "true"
    cancelledInput.disabled = false

    event.target.classList.add("d-none")
    trNode.querySelector('[data-action="deliveries#unCancel"]').classList.remove("d-none")
  }

  unCancel(event) {
    event.preventDefault()

    const trNode = event.target.closest("tr")

    trNode.classList.remove("strikeout")
    trNode.querySelectorAll("input, select").forEach((input) => {
      input.disabled = false
    })
    let cancelledInput = getInput(event, "cancelled")
    cancelledInput.value = "false"
    event.target.classList.add("d-none")
    trNode.querySelector('[data-action="deliveries#cancel"]').classList.remove("d-none")
  }
}
